import React, {
    useState, useCallback,
} from 'react'
import clsx from 'clsx'
import Card from 'app/shared-components/Card'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import {
    FilterPayload,
    useFilterContext,
} from 'app/hooks/useFilter'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import UnsavedChangesPrompt from 'app/shared-components/UnsavedChangesPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import SkyNetPagination, {
    useSkyNetPaginationContext,
} from 'app/shared-components/SkyNetPagination'
import useStyles from './SkyNetSpreadSheetControlPanel.styles'
import PreselectFilterTabs from './PreselectFilterTabs'
import {
    SkyNetSpreadsheetValidationContext,
    useSkyNetSpreadSheetValidation,
} from '../SkyNetSpreadsheetValidation'

const defaultProps = {
    onSave: undefined,
    onCancel: undefined,
    edited: false,
}
const SkyNetSpreadSheetControlPanel = ({
    children,
    onSave,
    onCancel,
    edited,
}: {
    children: JSX.Element,
    onSave?: (o?: AsyncMutationOptions) => void,
    onCancel?: (...args: any[]) => void,
    edited?: boolean,
}) => {
    const {
        classes,
    } = useStyles()
    const filterContext = useFilterContext()
    const validationContext = useSkyNetSpreadSheetValidation()
    const [
        pending,
        setPending,
    ] = useState<boolean>(false)

    const stopPending = useCallback(() => {
        setPending(false)
    }, [])

    const [
        preselectOption,
        setPreselectOption,
    ] = useState<FilterPayload>(undefined)

    const closeConfirm = useCallback(() => {
        setPreselectOption(undefined)
    }, [setPreselectOption])

    let preselectTabs = []

    const handleSave = useCallback(() => {
        if (!validationContext.valid) {
            validationContext.setShowError(true)
            return
        }

        setPending(true)

        onSave({
            onSettled: stopPending,
        })
    }, [
        onSave,
        stopPending,
        validationContext,
    ])

    const setFilter = useCallback((filter: FilterPayload) => {
        filterContext.clearFilters()
        filterContext.setFilter(filter || preselectOption)
    }, [
        filterContext,
        preselectOption,
    ])

    const saveBeforeSetFilter = useCallback(() => {
        onSave({
            onSuccess: setFilter,
            onSettled: stopPending,
        })
    }, [
        onSave,
        setFilter,
        stopPending,
    ])

    if (filterContext) {
        preselectTabs = Object.entries(filterContext?.config).reduce((acc, [
            key,
            value,
        ]) => {
            const {
                preselectOptions, filterField,
            } = value || {}

            if (preselectOptions) {
                const filterValue = filterContext.filter?.[filterField]

                return [
                    ...acc,
                    {
                        name: key,
                        filterField,
                        value: filterValue,
                        ...preselectOptions,
                    },
                ]
            }
            return acc
        }, [])
    }

    const pagination = useSkyNetPaginationContext()

    useBeforeUnloadDialog(edited)

    return (
        <div className={classes.wrapper}>
            <div className={clsx(classes.header, {
                [classes.preselect]: preselectTabs.length > 0,
            })}
            >
                {preselectTabs.length > 0
                    ? (
                        <PreselectFilterTabs
                            preselectTabs={preselectTabs}
                            setFilter={edited ? setPreselectOption : setFilter}
                        />
                    ) : null}
                <div className={classes.controls}>
                    { onSave ? (
                        <SaveIcon
                            className={classes.icon}
                            onClick={pending ? undefined : handleSave}
                        />
                    ) : null}
                    { onCancel ? (
                        <CloseIcon
                            className={classes.icon}
                            onClick={onCancel}
                        />
                    ) : null }
                </div>
            </div>
            <Card contentClass={classes.content}>
                <SkyNetSpreadsheetValidationContext.Provider value={validationContext}>
                    { children }
                </SkyNetSpreadsheetValidationContext.Provider>
            </Card>
            {pagination
                ? (
                    <div className={classes.pagination}>
                        <SkyNetPagination
                            total={pagination.matches}
                            rowsPerPage={pagination.rows}
                            page={pagination.page}
                            onChange={pagination.onChange}
                        />
                    </div>
                )
                : null}
            <HistoryBlockPrompt
                when={!pending && edited}
                action={onSave}
                discard={onCancel}
            />
            <UnsavedChangesPrompt
                open={Boolean(preselectOption)}
                close={closeConfirm}
                action={saveBeforeSetFilter}
                discard={setFilter}
            />
        </div>
    )
}

SkyNetSpreadSheetControlPanel.defaultProps = defaultProps

export default SkyNetSpreadSheetControlPanel
