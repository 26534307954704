import React from 'react'
import {
    Routes, Route, Navigate,
} from 'react-router-dom-v5-compat'
import {
    useRouteMatch,
} from 'react-router-dom'

import NewSimulation from './NewSimulation'
import SavedSimulation from './SavedSimulation'
import SavedSimulations from './SavedSimulations'
import Co2simulations from './Co2simulations'

const TransportPlanner = () => {
    const {
        path,
    } = useRouteMatch()

    return (
        <Routes>
            <Route
                path={`${path}/simulation`}
                element={<NewSimulation />}
            />
            <Route
                path={`${path}/simulation/:id`}
                element={<SavedSimulation transportPlannerPath={path} />}
            />
            <Route
                path={`${path}/co2Calculation`}
                element={<Co2simulations />}
            />
            <Route
                path={`${path}/savedSimulations`}
                element={<SavedSimulations transportPlannerPath={path} />}
            />
            <Route
                path={`${path}/*`}
                element={<Navigate to={`${path}/simulation`} />}
            />
        </Routes>
    )
}

export default TransportPlanner
